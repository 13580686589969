<template>
	<div class="main-content">
		<ktv-breadcrumb title="Farmer List" page="List" folder="Farmer" />
		<b-button variant="success" @click="$bvModal.show('mAdd')">+ Register New Account</b-button>		
		<Search
			:show-input-type="true" 
			:placeholder="'Search By ID, Name & Phone Number'"
			@change="onSearchFilter"
		/>
		
		<table class="table table-hover ">
			<thead class="thead">
				<tr class="">
					<th>Action</th>
					<th>Farmer ID</th>
					<th>Entity ID</th>
					<th>Name</th>
					<th>Commodity</th>
					<th>Platform</th>
					<th>Farmers</th>
					<th>District</th>					
					<th>Village</th>
					<th>Partner</th>
					<th>Phone Number</th>
					<th>Colection Date</th>
					<th>Update Date</th>
					<th>Is Cognito</th>
					<th>Is Register</th>
					<th>Register Date</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(a, key) in rows" :key="key">
					<td>
						<b-dropdown id="dropdown-1" text="" variant="link" class="m-md-2">
							<b-dropdown-item @click="openForm(a.EntityID, act = 'view')"><i class="fa fa-eye" aria-hidden="true"></i> &nbsp; View</b-dropdown-item>
							<b-dropdown-item @click="openForm(a.EntityID, act = 'update')"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Update</b-dropdown-item>
							<b-dropdown-item @click="openModalPassword(a)"><i class="fa fa-key" aria-hidden="true"></i>&nbsp; Reset Password</b-dropdown-item>
							<b-dropdown-item @click="unRegister(a)"><i class="fa fa-trash-o" aria-hidden="true"></i> &nbsp; Unregister</b-dropdown-item>
						</b-dropdown>
					</td>
					<td>{{ a.farmerid }}</td>
					<td>{{ a.EntityID }}</td>
					<td>{{ a.PersonName }}</td>
					<td>{{ a.commodity_name }}</td>
					<td>{{ a.PlatformID }}</td>
					<td>{{ a.farmergroup_name }}</td>
					<td>{{ a.SubDistrictName }}</td>
					<td>{{ a.VillageName }}</td>
					<td>{{ a.PartnerName }}</td>
					<td>{{ a.PhoneNumber }}</td>
					<td>{{ formatDate(a.colection_date) }}</td>
					<td>{{ formatDate(a.update_date) }}</td>
					<td>
						<div v-if="a.is_cognito === 'Yes'">
							<b-button variant="success" style="width:100%; font-size:11px">Yes</b-button>	
						</div>
						<div v-else>
							<b-button variant="danger" style="width:100%; font-size:11px">No</b-button>	
						</div>
					</td>
					<td>
						<div v-if="a.is_register === 'Yes'">
							<b-button variant="success" style="width:100%; font-size:11px">Yes</b-button>	
						</div>
						<div v-else>
							<b-button variant="danger" style="width:100%; font-size:11px">No</b-button>	
						</div>
					</td>
					<td>{{ a.RegisterDate }}</td>
				</tr>
			</tbody>
		</table>

		<paginationBarCustom 
			:state="paginationData" 
			@paginate="onPageChange()"
		/>
		<!-- MODAL ADD -->
		<b-modal id="mAdd" title="FarmCloud - Register New Account" size="xl" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer>
			<b-row>
				<b-col>
					<b-card style="background: #f5f5f5;">
						<label><b>Select Options :</b></label>
						<b-form-radio-group v-slot="{ ariaDescribedby }" label="Select Options : ">
							<b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Manual</b-form-radio>
							<b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="2">Bulk</b-form-radio>
						</b-form-radio-group>
					</b-card>
				</b-col>
			</b-row>
			<br>


			<template v-if="selected == 1">
				<b-row>
					<b-col>
						<b-form-group id="fieldset-1" description="">
							<label for=""><b>Select Farmers</b></label>
							<v-select
								v-model="farmer"
								v-validate="'required'"
								:options="farmers"
								:loading="loading"
								laceholder="Enter Farmer ID or  Name to Search"
								:state="validateState('farmer')"
								name="farmer" @search="searchFarmers"
							/>
							<small class="text-danger">
								{{ veeErrors.first("farmer") }}
							</small>
						</b-form-group>

						<hr>

						<b-form-group id="fieldset-1" disabled description="" label="Farmer ID">
							<b-form-input id="input-1" v-model="farmerid"></b-form-input>
						</b-form-group>

						<b-form-group id="fieldset-1" disabled description="" label="Farmer Name">
							<b-form-input id="input-1" v-model="PersonName"></b-form-input>
						</b-form-group>

						<b-form-group id="fieldset-1" disabled description="" label="Partner">
							<b-form-input id="input-1" v-model="partner"></b-form-input>
						</b-form-group>

						<b-form-group id="fieldset-1" disabled description="" label="Commodity">
							<b-form-input id="input-1" v-model="commodity_name"></b-form-input>
						</b-form-group>

						<b-form-group id="fieldset-1" disabled description="" label="User Name">
							<b-form-input id="input-1" v-model="farmerid"></b-form-input>
						</b-form-group>

						<b-form-group id="fieldset-1" disabled description="" label="Email">
							<b-form-input id="input-1" v-model="Email"></b-form-input>
						</b-form-group>

						<label for=""><b>Phone Number</b></label>
						<b-input-group id="fieldset-1" prepend="+" class="mt-1">
							<b-form-input 
								v-model="PhoneNumber" 
								v-validate="'required'"
								type="number"
								:state="validateState('phoneNumber')" 
								name="phoneNumber"
							></b-form-input>
						</b-input-group>
						<small class="text-danger">
							{{ veeErrors.first('phoneNumber') }}
						</small>
						<div class="">	
							<i>*Number format must use country code. (Ex : +62)</i>
						</div>
						
						<br>

						<b-form-group id="fieldset-1" description="" label="Password">
							<b-form-input 
								ref="password" v-model="password" 
								v-validate="'required'" 
								:state="validateState('password')" 
								name="password" 
								:type="showPass ? 'text' : 'password'"
							></b-form-input>

							<b-form-invalid-feedback>
								{{ veeErrors.first('password') }}
							</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group id="fieldset-1" description="" label="Re-type Password">
							<b-form-input 
								v-model="repassword" 
								v-validate="'required'" 
								data-vv-as="password" 
								:state="validateState('repassword')" name="repassword" 
								:type="showPass ? 'text' : 'password'"
							></b-form-input>

							<b-form-invalid-feedback>
								{{ veeErrors.first('repassword') }}
							</b-form-invalid-feedback>
						</b-form-group>

						<b-form-checkbox
							id="checkbox-1"
							v-model="showPass"
							name="checkbox-1"
							value="accepted"
						>
							Show Password
						</b-form-checkbox>
						<br>
					</b-col>
				</b-row>
			</template>

			<template v-if="selected == 2">
				<b-row>
					<b-col>
						<a
							href="https://koltiva-central.s3.ap-southeast-1.amazonaws.com/frontend/bulk-farmer-upload-template/Farmer_Upload_Template.csv"
						>	Download Format
						</a>
						<br><br>
						<b-file
							ref="filecsv"
							v-validate="'required'"
							size="sm"
							accept=".csv"
							:placeholder="`Choose a file ..`"
							:state="validateState('file')"
							name="file"
							@click.native="resetFile()"
						/>
						<small class="text-danger">
							{{ veeErrors.first("file") }}
						</small>
						<br>
					</b-col>
				</b-row>
			</template>

			<div class="modal-footer">
				<ktv-button
					class="mt-3"
					text="Cancel"
					icon="close"
					color="light"
					@click="$bvModal.hide('mAdd', selected = ''), allFarmers(), clearModal()"
				/>

				<ktv-button v-if="selected == 1"
					class="mt-3"
					text="Register Account"
					icon="save"
					color="primary"
					@click="storeData()"
				/>
				<ktv-button v-if="selected == 2"
					class="mt-3"
					text="Upoad FIle"
					icon="save"
					color="primary"
					@click="storeFile()"
				/>
			</div>
		</b-modal>

		<!-- MODAL RESET PASSWORD -->
		<b-modal id="mPassword" :title="`New Password - ${item.PersonName}`" size="md" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer>
			<b-row>
				<b-col>
					<b-form-group id="fieldset-1" description="" label="Password">
						<b-form-input ref="password" v-model="password" v-validate="'required'" :state="validateState('password')" name="password" :type="showPass ? 'text' : 'password'"></b-form-input>

						<b-form-invalid-feedback>
							{{ veeErrors.first('password') }}
						</b-form-invalid-feedback>
					</b-form-group>

					<b-form-group id="fieldset-1" description="" label="Re-type Password">
						<b-form-input v-model="repassword" v-validate="'required'" data-vv-as="password" :state="validateState('repassword')" name="repassword" :type="showPass ? 'text' : 'password'"></b-form-input>
						<b-form-invalid-feedback>
							{{ veeErrors.first('repassword') }}
						</b-form-invalid-feedback>
					</b-form-group>



					<b-form-checkbox
						id="checkbox-1"
						v-model="showPass"
						name="checkbox-1"
						value="accepted"
					>
						Show Password
					</b-form-checkbox>
				</b-col>
			</b-row>
			<br>



			<div class="modal-footer">
				<ktv-button
					class="mt-3"
					text="Cancel"
					icon="close"
					color="light"
					@click="closeModalPassword()"
				/>

				<ktv-button
					class="mt-3"
					text="Save"
					icon="save"
					color="primary"
					@click="resetPassword()"
				/>
			</div>
		</b-modal>

		<!-- MODAL RESULT -->
		<!-- <b-modal id="mResult" :title="`Results - Success ${dataResults.count.success}, Failed ${dataResults.count.failed}`" size="lg" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer> -->
		<b-modal id="mResult" :title="`Results`" size="lg" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer>
			<b-row>
				<b-col>
					<label for=""><b>Success Registered</b></label>
					<table class="table table-hover ">
						<thead class="thead">
							<tr class="">
								<th>No.</th>
								<th>Entity ID</th>
								<th>Farmer ID</th>
								<th>Message</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(a, key) in dataResults" :key="key">
								<template v-if="a.status === 'true'">
									<td>{{ key }}</td>								
									<td>{{ a.entityID }}</td>
									<td>{{ a.farmerID }}</td>
									<td>{{ a.message }}</td>
								</template>
							</tr>
						</tbody>
					</table>
				</b-col>
				<b-col>
					<label for=""><b>Failed Registered</b></label>
					<table class="table table-hover ">
						<thead class="thead">
							<tr class="">
								<th>No.</th>
								<th>Entity ID</th>
								<th>Farmer ID</th>
								<th>Message</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(a, key) in dataResults" :key="key">
								<template v-if="a.status === 'false'">								
									<td>{{ key }}</td>
									<td>{{ a.entityID }}</td>
									<td>{{ a.farmerID }}</td>
									<td>{{ a.message }}</td>
								</template>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>
			<br>



			<div class="modal-footer">
				<ktv-button
					class="mt-3"
					text="Close"
					icon="close"
					color="light"
					@click="$bvModal.hide('mResult'), loadItems()"
				/>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import {
		KtvButton,
	} from "@/components";
	import paginationBarCustom from "@/components/paginationBarCustom"
	import Search from "@/components/searchCustom"

	export default {
		metaInfo: {
			title: "Farmer",
		},
		components: {
			KtvButton,
			paginationBarCustom,
			Search,
		},
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */

				isLoading: false,
				modeTable: "remote",
				rows: [],
				totalRecords: 0,
				serverParams: {
					currentPage: 1,
					rowPerpage: 10,
					columnFilters: {},
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
					EntityID: "",
					PersonName: "",
					farmerid: "",
					PhoneNumber:"",				
				},
				selected:"",
				showPass: false,
				item:"",
				password:"",
				repassword:"",

				farmers:[],
				farmer:null,
				paramFarmers: {
					"columnFilters": {},
					EntityID: "",
					PersonName: "",
					farmerid: "",
					PhoneNumber:"",
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				EntityID:"",
				PersonName:"",
				farmerid:"",
				Email:"",
				PhoneNumber:"",
				commodity_name:"",
				// item:"",

				dataResults:[],
				
				paginationData: {
					pagination: {
						current_page: 1
					},
				},

			}
		},
		computed: {},
		watch: {
			farmer: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.findData()
					}
				}
			}

		},
		mounted() {
			this.loadItems()
			this.allFarmers()
		},
		methods: {
			resetFile() {
				this.$refs.filecsv.reset();
			},
			storeFile() {
				if(this.$refs.filecsv.files.length === 0){
					this.$swal({
						type: 'error',
						title: 'File cannot be empty',
					})
					return
				}
				this.$swal.fire({
					title: 'Submiting...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						let formData = new FormData()
						formData.append('data_csv', this.$refs.filecsv.files[0]);
						this.$http
							.Post(formData, this.$urlApi.farmer.registerBulk)
							.then(async (response) => {
								if(response.success === false){
									await this.$swal('Error!', response.error_message, 'error'); return
								}

								if(response.success === true){
									this.dataResults = response.results
									await this.$swal(
										'Submit Status',
										response.results.count.success + ' Success Registered, '  + response.results.count.failed + ' Failed Registered'  ,
										'info'
									);
									this.$bvModal.hide('mAdd')
									this.$bvModal.show('mResult')
								}

								// this.loadItems();
							})
							.catch(() => {
								this.$swal({
									type: 'error',
									title: 'Upload Failed',
								})
							});
					}
				})
			},
			clearModal(){
				this.farmer = null;
				this.farmerid = null;
				this.PersonName = null;
				this.EntityID = null
				this.PhoneNumber = null
				this.Email = null
				this.password = null
				this.commodity_name = null
			},
			storeData() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}

					if(this.password !== this.repassword){
						this.$swal('The password does not match!', '', 'error')
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.$swal.fire({
								title: 'Loading data...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let payload = {
										personname : this.PersonName,
										entitiyid : this.EntityID,
										phonenumber : '+' + this.PhoneNumber,
										email : this.Email,
										password : this.password,
									}
									this.$http.
										Post(payload, this.$urlApi.farmer.register)
										.then(async (r) => {
											if(r.success === true){
												await this.$swal('Success!', 'Successfully.', 'success')
												this.$bvModal.hide('mAdd')

											}
											if(r.success === false){
												await this.$swal('Error!', r.error_message, 'error'); return
											}

											await this.loadItems()
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});
				});

			},
			findData() {
				let a = this.farmers.find(o => o.id === this.farmer.id);
				this.EntityID = a.objectData.EntityID;
				this.PersonName = a.objectData.PersonName;
				this.farmerid = a.objectData.farmerid;
				this.Email = a.objectData.Email;
				this.PhoneNumber = a.objectData.PhoneNumber;
				this.commodity_name = a.objectData.commodity_name;
			},
			uParams(value) {
				this.paramFarmers = Object.assign({}, this.paramFarmers, value)
			},
			searchFarmers(search, loading) {
				loading(true);
				this.uParams({
					EntityID: search,
					PersonName: search,
					farmerid: search,
				})
				this.allFarmers(loading)
			},
			allFarmers(loading) {
				this.$http
					.Post(this.paramFarmers, this.$urlApi.farmer.list)
					.then((r) => {
						this.farmers = [];
						r.results.data.forEach(async (value, index) => {
							await this.farmers.push({
								index: index,
								id: value.EntityID,
								farmerid: value.farmerid,
								label: value.farmerid + " - " + value.PersonName,
								objectData: value,
							});
						});
						loading(false);
					})
					.catch(() => {
						this.isLoading = false
						// this.$swal("Error!", error.response, "error")
					})
				this.updateParams({ currentPage: 1 })

			},
			// load items is what brings back the rows from server
			loadItems() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						this.$http
							.Post(this.serverParams, this.$urlApi.farmer.list)
							.then((response) => {
								this.isLoading = false
								if (response.success) {
									this.rows = response.results.data
									this.paginationData.pagination = response.results
								} else {
									this.$swal("Error!", response.error_massage, "error")
								}
							})
							.catch((error) => {
								this.$swal("Error!", error.response, "error")
							}).finally(() => this.$swal.close())
					}
				})
				this.updateParams({ currentPage: 1 })
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange() {
				this.updateParams({ currentPage: this.paginationData.pagination.current_page })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(value) {
				this.updateParams({
					EntityID: value.Name,
					PersonName: value.Name,
					farmerid: value.Name,
					PhoneNumber: value.Name					
				})
				this.loadItems()
			},
			openModal(){

			},
			openForm(id, act) {
				if(act === 'view'){
					this.$router.push(`/farmer/detailFarmer?id=${id}&act=view`)
				}

				if(act === 'update'){
					this.$router.push(`/farmer/detailFarmer?id=${id}&act=update`)
				}
			},
			unRegister(item) {
				this.item = item;
				this.$swal({
					title: 'Are you sure?',
					text: this.item.PersonName + "will be Unregister!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
				}).then((result) => {
					if (result.value) {
						this.$swal.fire({
							title: 'Unregister data...',
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
								let params = { 
									PhoneNumber : this.item.PhoneNumber,
									EntityID : this.item.EntityID 
								}
								this.$http.
									Post(params, this.$urlApi.farmer.unregister)
									.then(async (r) => {
										if(r.success === true){
											await this.$swal('Success!', 'Data has been Unregistered.', 'success'); return
										}
										if(r.success === false){
											await this.$swal('Error!', r.error_message, 'error'); return
										}

										await this.loadItems()
									})
									.catch(async (error) => {
										await this.$swal("Error!", error , "error")
									}).finally(() => this.$swal.close())
							}
						})
					}
				});
			},
			resetPassword() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					if(this.password !== this.repassword){
						this.$swal('The password does not match!', '', 'error')
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "Reset to Default Password?",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((r) => {
						if (r.value) {
							this.$swal.fire({
								title: 'Loading...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let params = {
										PhoneNumber : this.item.PhoneNumber,
										Password : this.password
									}
									this.$http.
										Post(params, this.$urlApi.farmer.resetPassword)
										.then(async (response) => {
											if(response.success === true){
												await this.$swal('Success!', 'Successfully Reset Password.', 'success');
												this.$bvModal.hide('mPassword')
											}

											if(response.success === false){
												await this.$swal('Error!', response.error_message, 'error'); return
											}

											await this.loadItems()
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});

				});

			},
			openModalPassword(item){
				this.item = item;
				this.$bvModal.show('mPassword')
			},
			closeModalPassword(){
				this.password = "";
				this.repassword = "";
				this.showPass = false;
				this.$bvModal.hide('mPassword')
			},
		}
	}
</script>

<style>
.dropdownitem-action:hover a,
.dropdownitem-action:focus a {
	background-color: #CAEAFF !important;
}
</style>